import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import RegistrationForm from "./RegistrationForm";
import ConfirmPage from './ConfirmPage';
import CancelPage from './CancelPage';
import CancelCompletePage from './CancelCompletePage';
import DashboardPage from './DashboardPage';
import DashboardDetailPage from './DashboardDetailPage';
import DashboardDetailRedeemPage from './DashboardDetailRedeemPage';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import PasswordPage from './PasswordPage';
import ComingSoon from './ComingSoon';

import RegistrationFormSiamParagonTMG from "./RegistrationFormSiamParagonTMG";
import ConfirmPageSiamParagonTMG from './ConfirmPageSiamParagonTMG';
import CancelPageSiamParagonTMG from './CancelPageSiamParagonTMG';
import CancelCompletePageSiamParagonTMG from './CancelCompletePageSiamParagonTMG';

import RegistrationFormSiamParagonCU from "./RegistrationFormSiamParagonCU";
import ConfirmPageSiamParagonCU from './ConfirmPageSiamParagonCU';
import CancelPageSiamParagonCU from './CancelPageSiamParagonCU';
import CancelCompletePageSiamParagonCU from './CancelCompletePageSiamParagonCU';

import RegistrationFormAddictLipGlowRenoSiamParagonTMG from "./RegistrationFormAddictLipGlowRenoSiamParagonTMG";
import ConfirmPageAddictLipGlowRenoSiamParagonTMG from "./ConfirmPageAddictLipGlowRenoSiamParagonTMG";
import CancelPageAddictLipGlowRenoSiamParagonTMG from "./CancelPageAddictLipGlowRenoSiamParagonTMG";
import CancelCompletePageAddictLipGlowRenoSiamParagonTMG from "./CancelCompletePageAddictLipGlowRenoSiamParagonTMG";

import AddictLipGlowMakeupVoucherWelcome from './AddictLipGlowMakeupVoucherWelcome';
import AddictLipGlowMakeupVoucher from './AddictLipGlowMakeupVoucher';
import AddictLipGlowMakeupVoucherComplete from './AddictLipGlowMakeupVoucherComplete';

import RegistrationFormAddictLipGlowRenoIconSiamVZ from "./RegistrationFormAddictLipGlowRenoIconSiamVZ";
import ConfirmPageAddictLipGlowRenoIconSiamVZ from './ConfirmPageAddictLipGlowRenoIconSiamVZ';
import CancelPageAddictLipGlowRenoIconSiamVZ from './CancelPageAddictLipGlowRenoIconSiamVZ';
import CancelCompletePageAddictLipGlowRenoIconSiamVZ from './CancelCompletePageAddictLipGlowRenoIconSiamVZ';

import RegistrationFormAddictLipGlowRenoMegaBangnaCDS from "./RegistrationFormAddictLipGlowRenoMegaBangnaCDS";
import ConfirmPageAddictLipGlowRenoMegaBangnaCDS from './ConfirmPageAddictLipGlowRenoMegaBangnaCDS';
import CancelPageAddictLipGlowRenoMegaBangnaCDS from './CancelPageAddictLipGlowRenoMegaBangnaCDS';
import CancelCompletePageAddictLipGlowRenoMegaBangnaCDS from './CancelCompletePageAddictLipGlowRenoMegaBangnaCDS';

import RegistrationFormAddictLipGlowRenoCentralLardpraoCDS from "./RegistrationFormAddictLipGlowRenoCentralLardpraoCDS";
import ConfirmPageAddictLipGlowRenoCentralLardpraoCDS from './ConfirmPageAddictLipGlowRenoCentralLardpraoCDS';
import CancelPageAddictLipGlowRenoCentralLardpraoCDS from './CancelPageAddictLipGlowRenoCentralLardpraoCDS';
import CancelCompletePageAddictLipGlowRenoCentralLardpraoCDS from './CancelCompletePageAddictLipGlowRenoCentralLardpraoCDS';

import RegistrationFormForeverSiamParagon from "./RegistrationFormForeverSiamParagon";
import CancelPageForeverSiamParagon from './CancelPageForeverSiamParagon';
import ConfirmPageForeverSiamParagon from './ConfirmPageForeverSiamParagon';
import CancelCompletePageForeverSiamParagon from './CancelCompletePageForeverSiamParagon';

import RegistrationFormForeverCentralWorld from "./RegistrationFormForeverCentralWorld";
import CancelPageForeverCentralWorld from './CancelPageForeverCentralWorld';
import ConfirmPageForeverCentralWorld from './ConfirmPageForeverCentralWorld';
import CancelCompletePageForeverCentralWorld from './CancelCompletePageForeverCentralWorld';

import RegistrationFormForeverMegaBangna from "./RegistrationFormForeverMegaBangna";
import CancelPageForeverMegaBangna from './CancelPageForeverMegaBangna';
import ConfirmPageForeverMegaBangna from './ConfirmPageForeverMegaBangna';
import CancelCompletePageForeverMegaBangna from './CancelCompletePageForeverMegaBangna';

import DashboardDeatailBcPage from './DashboardDeatailBcPage';
import LoginPageBc from './LoginPageBc';
import DashboardDeatailBcSiamParagonPage from './DashboardDeatailBcSiamParagonPage';
import LoginPageBcSiamParagon from './LoginPageBcSiamParagon';
import DashboardDeatailBcCentralWorldPage from './DashboardDeatailBcCentralWorldPage';
import LoginPageBcCentralWorld from './LoginPageBcCentralWorld';
import DashboardDeatailBcMegaBangnaPage from './DashboardDeatailBcMegaBangnaPage';
import LoginPageBcMegaBangna from './LoginPageBcMegaBangna';

const App = () => {
    const [checkScreen, setCheckScreen] = useState(false);

    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        return localStorage.getItem('isAuthenticated') === 'true';
    });

    useEffect(() => {
        if (!isAuthenticated) {
            localStorage.removeItem('isAuthenticated');
            localStorage.removeItem('token'); // ลบ JWT token ออกจาก localStorage
        }
    }, [isAuthenticated]);

    const handleLogout = () => {
        setIsAuthenticated(false);
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('token'); // ลบ JWT token ออกจาก localStorage
    };


    const handlePasswordSubmit = (inputPassword) => {
        const correctPassword = 'MXCKWGCX'; // Set your desired password here
        if (inputPassword === correctPassword) {
          setCheckScreen(true);
        } else {
          alert('Incorrect password!');
        }
      };
    
    return (
        <Router>
            <div>
            {/* {checkScreen ? ( */}
                <Routes>
                    {/* Public Routes */}
                    <Route path="DiorBallOfDreams/IconSiam/VZ" element={<PasswordPage onPasswordSubmit={handlePasswordSubmit} />} />

                    <Route path="DiorBallOfDreams/CentralWorld/CDS" element={<PasswordPage onPasswordSubmit={handlePasswordSubmit} />} />

                    <Route path="DiorBallOfDreams/SiamParagon/TMG" element={<PasswordPage onPasswordSubmit={handlePasswordSubmit} />} />

                    <Route path="HPPAddictLipGlow/SiamParagon/TMG" element={<PasswordPage onPasswordSubmit={handlePasswordSubmit} />} />

                    <Route path="HPPAddictLipGlow/SiamParagon/CU" element={<PasswordPage onPasswordSubmit={handlePasswordSubmit} />} />

                    {/* <Route path="AddictLipGlow/MakeupVoucher" element={<ComingSoon onPasswordSubmit={handlePasswordSubmit} />} /> */}
                    <Route path="AddictLipGlow/MakeupVoucher" element={<AddictLipGlowMakeupVoucherWelcome />} />
                    <Route path="AddictLipGlow/MakeupVoucher/registration" element={<AddictLipGlowMakeupVoucher />} />
                    <Route path="AddictLipGlow/MakeupVoucher/complete" element={<AddictLipGlowMakeupVoucherComplete />} />

                    {/* IconSiam/VZ */}
                    <Route path="HPPAddictLipGlow/IconSiam" element={<PasswordPage onPasswordSubmit={handlePasswordSubmit} />} />

                    {/* MegaBangna/CDS */}
                    <Route path="HPPAddictLipGlow/MegaBangna" element={<PasswordPage onPasswordSubmit={handlePasswordSubmit} />} />

                    {/* CentralLardprao/CDS */}
                    <Route path="HPPAddictLipGlow/CentralLardprao" element={<PasswordPage onPasswordSubmit={handlePasswordSubmit} />} />

                    {/* user */}
                    {/* <Route path="Forever/SiamParagon" element={<ComingSoon onPasswordSubmit={handlePasswordSubmit} />} /> */}
                    {/* <Route path="Forever/CentralWorld" element={<ComingSoon onPasswordSubmit={handlePasswordSubmit} />} /> */}
                    {/* <Route path="Forever/MegaBangna" element={<ComingSoon onPasswordSubmit={handlePasswordSubmit} />} /> */}
                    
                    <Route path="Forever/SiamParagon" element={<RegistrationFormForeverSiamParagon />} />
                    <Route path="Forever/SiamParagon/registration" element={<RegistrationFormForeverSiamParagon />} />
                    <Route path="Forever/SiamParagon/confirmation" element={<ConfirmPageForeverSiamParagon />} />
                    <Route path="Forever/SiamParagon/cancellation" element={<CancelPageForeverSiamParagon />} />
                    <Route path="Forever/SiamParagon/cancelComplete" element={<CancelCompletePageForeverSiamParagon />} />

                    <Route path="Forever/CentralWorld" element={<RegistrationFormForeverCentralWorld />} />
                    <Route path="Forever/CentralWorld/registration" element={<RegistrationFormForeverCentralWorld />} />
                    <Route path="Forever/CentralWorld/confirmation" element={<ConfirmPageForeverCentralWorld />} />
                    <Route path="Forever/CentralWorld/cancellation" element={<CancelPageForeverCentralWorld />} />
                    <Route path="Forever/CentralWorld/cancelComplete" element={<CancelCompletePageForeverCentralWorld />} />

                    <Route path="Forever/MegaBangna" element={<RegistrationFormForeverMegaBangna />} />
                    <Route path="Forever/MegaBangna/registration" element={<RegistrationFormForeverMegaBangna />} />
                    <Route path="Forever/MegaBangna/confirmation" element={<ConfirmPageForeverMegaBangna />} />
                    <Route path="Forever/MegaBangna/cancellation" element={<CancelPageForeverMegaBangna />} />
                    <Route path="Forever/MegaBangna/cancelComplete" element={<CancelCompletePageForeverMegaBangna />} />

                    {/* bc */}
                    {/* <Route path="/Forever/bc/login" element={<RegistrationFormForeverSPG />} /> */}
                    <Route path="/Forever/bc/detail" element={<DashboardDeatailBcPage handleLogout={handleLogout}/>} />
                    <Route path="/Forever/bc/login" element={<LoginPageBc />} />
                    
                    <Route path="/Forever/SiamParagon/bc/detail" element={<DashboardDeatailBcSiamParagonPage handleLogout={handleLogout}/>} />
                    <Route path="/Forever/SiamParagon/bc/login" element={<LoginPageBcSiamParagon />} />

                    <Route path="/Forever/CentralWorld/bc/detail" element={<DashboardDeatailBcCentralWorldPage handleLogout={handleLogout}/>} />
                    <Route path="/Forever/CentralWorld/bc/login" element={<LoginPageBcCentralWorld />} />

                    <Route path="/Forever/MegaBangna/bc/detail" element={<DashboardDeatailBcMegaBangnaPage handleLogout={handleLogout}/>} />
                    <Route path="/Forever/MegaBangna/bc/login" element={<LoginPageBcMegaBangna />} />

                    <Route 
                        path="/registerPage" 
                        element={isAuthenticated ? <RegisterPage /> : <Navigate to="/login" />} 
                    />
                    {/* Protected Routes */}
                    <Route path="/dashboard" 
                        element={isAuthenticated ? <DashboardPage handleLogout={handleLogout} /> : <LoginPage />} 
                    />
                    <Route 
                        path="/dashboardDetail/:campaign_id/:campaign_name" 
                        element={isAuthenticated ? <DashboardDetailPage /> : <Navigate to="/login" />} 
                    />
                    <Route 
                        path="/dashboardDetailVoucher/:campaign_id/:campaign_name" 
                        element={isAuthenticated ? <DashboardDetailRedeemPage /> : <Navigate to="/login" />} 
                    />

                    {/* Login Route */}
                    <Route 
                        path="/login" 
                        element={<LoginPage />
                        } 
                    />
                </Routes>

            {/* ) : (
                <PasswordPage onPasswordSubmit={handlePasswordSubmit} />
            )} */}
            </div>
        </Router>
    );
};

export default App;