import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import eventImage from "./images/Main.jpg"; // Adjust the path as necessary
import ConsentModal from "./ConsentModal";
import { useLocation } from "react-router-dom";
import ConsentLink from "./ConsentLink";

const RegistrationFormForeverMegaBangna = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const location = useLocation();
  //state
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobilePhone: "",
    email: "",
    eventSelect: "",
    chkSMSMMS: false,
    chkEmail: false,
    chkConsent: false,
    chkConsentMkt: false,
    eventText: "",
    storeId: "31",
    storeNameTh: "เซ็นทรัลเมกาบางนา",
    partnerId: "2",
    partnerName: "Central",
    cpId: 879,
    uuid: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const inputRefs = {
    firstName: useRef(null),
    lastName: useRef(null),
    mobilePhone: useRef(null),
    email: useRef(null),
    eventSelect: useRef(null),
  };
  //modal
  const [modalStyle, setModalStyle] = useState({});
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [dupModalOpen, setDupModalOpen] = useState(false);
  const [notAvailableModalOpen, setNotAvailableModalOpen] = useState(false);
  const [inputErrModalOpen, setInputErrModalOpen] = useState(false);
  const [concentsModal, setConcentsModal] = useState(false);
  const [dupCancelModalOpen, setDupCancelModalOpen] = useState(false);
  //loading
  const [loading, setLoading] = useState(false);
  //date-tiem
  const [slotsData, setSlotsData] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  //mode add=false , edit=true
  const [modeUpdate, setModeUodate] = useState(false);

  const [scrapedContent, setScrapedContent] = useState("<p>Loading...</p>");
  
  useEffect(() => {
    fetchDateAndTimeBy();
    if (location.state !== null) {
      setModeUodate(true);
      setFormData((prevData) => ({
        ...prevData,
        firstName: location.state.firstName,
        lastName: location.state.lastName,
        mobilePhone: location.state.mobilePhone,
        email: location.state.email,
        uuid: location.state.uuid,
        oldslotid: location.state.oldslotid,
        chkSMSMMS: location.state.optinSmsMms,
        chkEmail: location.state.optinEdm,
        chkConsent: location.state.consentTandc,
        chkConsentMkt: location.state.consentTandc,
      }));
      // addSlots(location.state.odjDateTime);
    }
    const updateModalStyle = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      let maxHeight = "80vh"; // Default max height
      let maxWidth = "105vw";

      // Adjust maxHeight based on viewport width
      if (width >= 1200) {
        maxHeight = "100vh";
      } else if (width >= 992) {
        maxHeight = "90vh";
      }
      // Adjust maxWidth based on viewport width
      if (width >= 1200) {
        maxWidth = "85vw";
      } else if (width >= 992) {
        maxWidth = "95vw";
      }

      // Adjust maxHeight for landscape orientation
      if (window.matchMedia("(orientation: landscape)").matches) {
        // maxHeight = '50vh';
        maxHeight = "60vh";
        maxWidth = "105vw"; // Adjust width for landscape mode
      }

      setModalStyle({
        maxHeight: maxHeight,
        overflowY: "auto",
        overflowX: "auto",
        padding: "0px",
      });
    };

    updateModalStyle(); // Initial call
    window.addEventListener("resize", updateModalStyle); // Update on resize

    return () => {
      window.removeEventListener("resize", updateModalStyle); // Clean up
    };
  }, []);
  const addSlots = (newSlots) => {
    setSlotsData(prevSlots => {
        const existingIds = new Set(prevSlots.map(slot => slot.slot_id));
        
        // Filter out duplicates
        const filteredNewSlots = newSlots.filter(slot => !existingIds.has(slot.slot_id));

        // Return updated state only if there are new slots
        return filteredNewSlots.length ? [...prevSlots, ...filteredNewSlots] : prevSlots;
    });
};
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log(slotsData);
    
    // Update form data based on input type
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));

    // Optional: Validate input fields as they change
    const errors = { ...formErrors };
    if (name !== "chkSMSMMS" && name !== "chkEmail" && name !== "chkConsent" && name !== "chkConsentMkt") {
      if (!value) {
        errors[name] = "กรุณากรอกข้อมูล";
      } else {
        delete errors[name];
      }
    }

    console.log(name);
    console.log(value);

    // ตรวจสอบการเลือกวัน (`dateSelect`)
    if (name === "dateSelect") {
      setSelectedDate(value);
      setSelectedTime(""); // รีเซ็ตค่าเวลาเมื่อเปลี่ยนวันที่

      if (!value) {
        errors.dateSelect = "กรุณาเลือกวันที่";
      } else {
        delete errors.dateSelect;
      }

      // รีเซ็ตค่า timeSelect เมื่อเปลี่ยนวันที่
      setFormData((prevData) => ({
        ...prevData,
        timeSelect: "", // รีเซ็ตค่า
      }));
      delete errors.timeSelect;
    }
    // ตรวจสอบการเลือกเวลา (`timeSelect`)
    if (name === "timeSelect") {
      setSelectedTime(value);
      if (!value) {
        errors.timeSelect = "กรุณาเลือกรอบเวลา";
      } else {
        delete errors.timeSelect;
      }
    }

    // Handle checkbox group validation
    if (name === "chkSMSMMS" || name === "chkEmail") {
      if (!formData.chkEmail && !formData.chkSMSMMS) {
        delete errors.chkCommunication;
      } else if (name === "chkSMSMMS" && !formData.chkEmail) {
        errors.chkCommunication = "กรุณาเลือกอย่างน้อยหนึ่งตัวเลือก";
      } else if (name === "chkEmail" && !formData.chkSMSMMS) {
        errors.chkCommunication = "กรุณาเลือกอย่างน้อยหนึ่งตัวเลือก";
      } else {
        delete errors.chkCommunication;
      }
    }

    if (name === "chkConsent") {
      if (!checked) {
        errors.chkConsent = "กรุณายอมรับเงื่อนไข";
      } else {
        delete errors.chkConsent;
      }
    }
    if (name === "chkConsentMkt") {
      if (!checked) {
        errors.chkConsentMkt = "กรุณายอมรับเงื่อนไข";
      } else {
        delete errors.chkConsentMkt;
      }
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (name === "email" && value && !emailPattern.test(value)) {
      errors.email = "อีเมลไม่ถูกต้อง";
    } else if (!value) {
      errors[name] = "กรุณากรอกข้อมูล";
    } else if (name === "email") {
      delete errors.email;
    }

    if (name === "mobilePhone") {
      const filteredValue = value.replace(/[^0-9]/g, "");
      if (
        (filteredValue.length < 10 || !filteredValue.startsWith("0")) &&
        filteredValue !== ""
      ) {
        errors.mobilePhone =
          "หมายเลขโทรศัพท์มือถือจะต้องขึ้นต้นด้วย 0 และมีความยาวอย่างน้อย 10 หลัก";
      } else if (!filteredValue) {
        errors.mobilePhone = "กรุณากรอกข้อมูล";
      } else {
        delete errors.mobilePhone;
      }
      // อัพเดตข้อมูลฟอร์มด้วยค่าที่กรองแล้ว
      setFormData((prevData) => ({
        ...prevData,
        [name]: filteredValue,
      }));
    }
    console.log(errors);
    setFormErrors(errors);
  };

  const handleConfirmClick = (e) => {
    e.preventDefault();

    const errors = {};

    Object.keys(formData).forEach((key) => {
      if (key !== "chkSMSMMS" && key !== "chkEmail" && key !== "chkConsent" && key !== "chkConsentMkt") {
        if (!formData[key]) {
          errors[key] = "กรุณากรอกข้อมูล";
        }
      }
    });

    // ตรวจสอบการเลือกวันและเวลา
    if (!selectedDate) {
      errors.dateSelect = "กรุณาเลือกวันที่";
    }
    if (!selectedTime) {
      errors.timeSelect = "กรุณาเลือกรอบเวลา";
    }

    if (!formData.chkSMSMMS && !formData.chkEmail) {
      errors.chkCommunication = "กรุณาเลือกอย่างน้อยหนึ่งตัวเลือก";
    }

    if (!formData.chkConsent) {
      errors.chkConsent = "กรุณายอมรับเงื่อนไข";
    }
    if (!formData.chkConsentMkt) {
      errors.chkConsentMkt = "กรุณายอมรับเงื่อนไข";
    }

    // Additional validations
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.email && !emailPattern.test(formData.email)) {
      errors.email = "อีเมลไม่ถูกต้อง";
    }

    const mobilePattern = /^[0-9]+$/;
    if (formData.mobilePhone && !mobilePattern.test(formData.mobilePhone)) {
      errors.mobilePhone = "หมายเลขโทรศัพท์มือถือจะต้องเป็นตัวเลขเท่านั้น";
    } else if (
      formData.mobilePhone &&
      (formData.mobilePhone.length < 10 ||
        !formData.mobilePhone.startsWith("0"))
    ) {
      errors.mobilePhone =
        "หมายเลขโทรศัพท์มือถือจะต้องขึ้นต้นด้วย 0 และมีความยาว 10 หลัก";
    }

    console.log(Object.keys(errors).length);

    if (
      Object.keys(errors).length < 4
      && (selectedTime !== '' || selectedTime === undefined)
    ) {
      setConfirmationModalOpen(true);
      const slotId = findSlotId(selectedDate, selectedTime);
      setFormData((prevFormData) => ({
        ...prevFormData,
        booking: slotId,
      }));
    } else {
      setFormErrors(errors);
      setInputErrModalOpen(true);
    }
  };

  const handleConfirmationConfirm = async () => {
    await handleSubmit();
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${apiUrl}/check-dup-and-insert-booking`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
          crossdomain: true,
        }
      );

      setConfirmationModalOpen(false);

      if (response.status === 201) {
        const lotObj = findSlotById(formData.booking);

        navigate("/Forever/MegaBangna/confirmation", {
          state: {
            // value: formData.storeNameTh
            // value: formData.dateSelect,
            value: lotObj,
          },
        });
      } else if (
        response.data.message ===
        "Duplicate entry: Email or mobile phone already exists."
      ) {
        console.log(response.data.message);
        setDupModalOpen(true);
      } else if (response.data.message === "Not available") {
        console.log(response.data.message);
        setNotAvailableModalOpen(true);
      } else if (response.data.message === "Not found.") {
        console.log(response.data.message);
        setDupCancelModalOpen(true);
      }
    } catch (error) {
      setConfirmationModalOpen(false);
      setErrorModalOpen(true); // Open the error modal

      console.error("API error:", error); // Optional: Log the error for debugging
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleConfirmationClose = () => setConfirmationModalOpen(false);

  const handleErrorClose = () => {
    setErrorModalOpen(false); // Close the modal
  };

  const handleDupClose = () => {
    setFormErrors({});
    setDupModalOpen(false);
  };

  const handleNotAvailableClose = () => {
    fetchDateAndTimeBy();

    const errors = { ...formErrors };
    // delete errors.dateSelect;
    errors.dateSelect = "กรุณาเลือกวันที่";
    delete errors.timeSelect;
    setFormErrors(errors);

    setSelectedTime("");
    setSelectedDate("");
    setNotAvailableModalOpen(false);
  };

  const handleInputErrClose = () => {
    setInputErrModalOpen(false);
  };
  
  const handelConcents = () => {
    setConcentsModal(false);
  };

  const handleDupCancelClose = () => {
    setModeUodate(true);
    navigate("/Forever/MegaBangna", { state: null }); // Clears state
    window.location.reload();
  };
  
  const fetchDateAndTimeBy = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${apiUrl}/getDateAndTimeBy`,
        {allbooking:true,
          storeId: formData.storeId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          crossdomain: true,
        }
      );

      if (response.status === 200) {
        setSlotsData(response.data.stores);
      } else {
        console.error("Failed to fetch stores:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching store options:", error);
    } finally {
      setLoading(false);
    }
  };

  const findSlotById = (slotId) => {
    // return slotsData.find(slot => slot.slot_id === slotId);
    console.log(slotsData);

    const stores = slotsData;
    console.log(stores);

    const slot = stores.find(
      (slot) => slot.slot_id === slotId
    );

    return slot ? slot : null;
  };

  const findSlotId = (date, time) => {
    console.log(slotsData);

    const stores = slotsData;
    console.log(stores);

    const slot = stores.find(
      (slot) => slot.date === date && slot.time === time
    );

    return slot ? slot.slot_id : null;
  };
  // ดึงวันที่ที่ไม่ซ้ำกัน
  //  const uniqueDates = [...new Set(slotsData.map((slot) => slot.date))];
  const uniqueDates = [
    ...new Map(
      slotsData.map((slot) => [
        slot.date,
        { date: slot.date, date_str: slot.date_str },
      ])
    ).values(),
  ];
  // ดึงเวลาตามวันที่ที่เลือก
  const filteredTimes = selectedDate ? slotsData.filter((slot) => slot.date === selectedDate) : [];


  return (
    <div>
      <div className="text-center">
        <img
          src={eventImage}
          alt="Event"
          // className="img-fluid mb-4"
          style={{ width: "100%", height: "auto", maxWidth: "700px" }}
        />
      </div>
      <Container style={{ margin: "0 auto", padding: "24px 24px" }}>
        <Form>
          <FormGroup>
            <Label for="titelCp">ลงทะเบียนเข้าร่วมกิจกรรม Forever Makeup Workshop</Label>
          </FormGroup>
          <FormGroup>
            <Label for="firstName">ชื่อ</Label>
            <Input
              id="firstName"
              name="firstName"
              type="text"
              value={formData.firstName}
              onChange={handleInputChange}
              invalid={!!formErrors.firstName}
              innerRef={inputRefs.firstName}
              disabled={modeUpdate}
            />
            <FormFeedback>{formErrors.firstName}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="lastName">นามสกุล</Label>
            <Input
              id="lastName"
              name="lastName"
              type="text"
              value={formData.lastName}
              onChange={handleInputChange}
              invalid={!!formErrors.lastName}
              innerRef={inputRefs.lastName}
              disabled={modeUpdate}
            />
            <FormFeedback>{formErrors.lastName}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="mobilePhone">โทรศัพท์มือถือ</Label>
            <Input
              id="mobilePhone"
              name="mobilePhone"
              type="text" // Use 'text' to manually control validation and length
              value={formData.mobilePhone}
              onChange={handleInputChange}
              invalid={!!formErrors.mobilePhone}
              // maxLength="20" // Enforces the length constraint in the UI
              maxLength="10" // Enforces the length constraint in the UI
              innerRef={inputRefs.mobilePhone}
              disabled={modeUpdate}
            />
            <FormFeedback>{formErrors.mobilePhone}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="email">อีเมล</Label>
            <Input
              id="email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              invalid={!!formErrors.email}
              innerRef={inputRefs.email}
              disabled={modeUpdate}
            />
            <FormFeedback>{formErrors.email}</FormFeedback>
          </FormGroup>
          {/* DDL วันที่ */}
          {!loading && (
            <FormGroup>
              <Label for="dateSelect">เลือกวันที่ในการเข้าร่วมกิจกรรม</Label>
              <Input
                id="dateSelect"
                name="dateSelect"
                type="select"
                value={selectedDate}
                onChange={handleInputChange}
                invalid={!!formErrors.dateSelect}
              >
                <option value="">กรุณาเลือกวันที่</option>
                {uniqueDates.map((dateObj) => (
                  <option key={dateObj.date} value={dateObj.date}>
                    {dateObj.date_str}{" "}
                    {/* Show `date_str` while keeping `date` as the value */}
                  </option>
                ))}
              </Input>
              <FormFeedback>{formErrors.dateSelect}</FormFeedback>
            </FormGroup>
          )}

          {/* DDL เวลา (Disabled เมื่อยังไม่เลือกวันที่) */}
          {!loading && (
            <FormGroup>
              <Label for="timeSelect">เวลา</Label>
              <Input
                id="timeSelect"
                name="timeSelect"
                type="select"
                value={selectedTime}
                onChange={handleInputChange}
                disabled={!selectedDate} // ปิดใช้งานถ้ายังไม่เลือกวันที่
                invalid={!!formErrors.timeSelect}
              >
                <option value="">เลือกรอบเวลา</option>
                {filteredTimes.map((slot) => (
                  <option key={slot.slot_id} value={slot.time}>
                    {/* {slot.time} */}
                    {slot.time_str}
                  </option>
                ))}
              </Input>
              <FormFeedback>{formErrors.timeSelect}</FormFeedback>
            </FormGroup>
          )}

          <hr style={{ margin: "25px auto" }} />
          <FormGroup>
            <Label style={{ marginBottom: "16px" }}>
              {/* ประสงค์จะรับข่าวสารจาก Forever ผ่านช่องทาง ต่อไปนี้ <br />I wish
              to receive the following communication(s) from Forever */}
              ประสงค์จะรับข่าวสารจาก ดิออร์ ผ่านช่องทาง ต่อไปนี้
            </Label>
            <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Label check>
              <Input
                type="checkbox"
                id="chkSMSMMS"
                name="chkSMSMMS"
                checked={formData.chkSMSMMS}
                onChange={handleInputChange}
                style={{
                  marginBottom: "18px",
                  transform: "scale(1.15)",
                  border: "2px solid",
                  borderRadius: "3px",
                  backgroundColor: formData.chkSMSMMS ? "black" : "white",
                  boxShadow: "none",
                }}
                disabled={modeUpdate}
              />
              &nbsp;&nbsp;ส่งข้อความ SMS/MMS
            </Label>
            {/* <br /> */}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Label check>
              <Input
                type="checkbox"
                id="chkEmail"
                name="chkEmail"
                checked={formData.chkEmail}
                onChange={handleInputChange}
                style={{
                  transform: "scale(1.15)",
                  border: "2px solid",
                  borderRadius: "3px",
                  backgroundColor: formData.chkEmail ? "black" : "white",
                  boxShadow: "none",
                }}
                disabled={modeUpdate}
              />
              &nbsp;&nbsp;อีเมล Email
            </Label>
            {formErrors.chkCommunication && (
              <FormFeedback className="d-block">
                {formErrors.chkCommunication}
              </FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <Label>
              <Input
                type="checkbox"
                id="chkConsent"
                name="chkConsent"
                checked={formData.chkConsent}
                onChange={handleInputChange}
                style={{
                  transform: "scale(1.15)",
                  border: "2px solid",
                  borderRadius: "3px",
                  backgroundColor: formData.chkConsent ? "black" : "white",
                  boxShadow: "none",
                }}
                disabled={modeUpdate}
              />
              &nbsp;&nbsp; ฉันได้อ่าน,
              ทำความเข้าใจและยอมรับเงื่อนไขสำหรับนโยบายความเป็นส่วนตัว 
              <ConsentLink />
              {formErrors.chkConsent && (
                <FormFeedback className="d-block">
                  {formErrors.chkConsent}
                </FormFeedback>
              )}
            </Label>
            <br/>
            <Label>
              <Input
                type="checkbox"
                id="chkConsentMkt"
                name="chkConsentMkt"
                checked={formData.chkConsentMkt}
                onChange={handleInputChange}
                style={{
                  transform: "scale(1.15)",
                  border: "2px solid",
                  borderRadius: "3px",
                  backgroundColor: formData.chkConsentMkt ? "black" : "white",
                  boxShadow: "none",
                }}
                disabled={modeUpdate}
              />
              &nbsp;&nbsp; ฉันได้อ่าน,
              ทำความเข้าใจและตกลงที่จะรับการสื่อสารการตลาดและการบริการ
            </Label>
            {formErrors.chkConsentMkt && (
              <FormFeedback className="d-block">
                {formErrors.chkConsentMkt}
              </FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <Label>
              หมายเหตุ<br />
              &nbsp;&nbsp;1)
              ข้อมูลทั้งหมดที่ท่านให้จะได้รับการเก็บรักษาไว้เป็นความลับ
              <br />
              &nbsp;&nbsp;2) กิจกรรมนี้มีการถ่ายภาพ บันทึกคลิปวีดีโอและ/หรือบันทึกเทปรายการ
            </Label>
          </FormGroup>
          {/* <FormGroup style={{ display: "flex", justifyContent: "center" }}>
            <ConsentModal />
          </FormGroup> */}
        </Form>
        <div style={{ textAlign: "center" }}>
          <Button
            style={{
              width: "100%",
              maxWidth: "200px",
              backgroundColor: "#000",
              borderColor: "#000",
              color: "#FFF",
              borderRadius: "1px",
              marginBottom: "50px",
            }}
            onClick={handleConfirmClick}
          >
            ยืนยัน Confirm
          </Button>
        </div>

        {/* Confirmation Modal */}
        <Modal isOpen={confirmationModalOpen} toggle={handleConfirmationClose}>
          <ModalHeader toggle={handleConfirmationClose}>
            ยืนยันการส่งข้อมูล
          </ModalHeader>
          <ModalBody>
            คุณต้องการยืนยันข้อมูลและส่งข้อมูลนี้หรือไม่?
            <br />
            Do you want to confirm and submit this information?
          </ModalBody>
          <ModalFooter>
            {loading ? (
              <Button hidden>ยกเลิก</Button>
            ) : (
              <Button
                style={{
                  backgroundColor: "#000",
                  borderColor: "#000",
                  color: "#FFF",
                  borderRadius: "1px",
                }}
                onClick={handleConfirmationClose}
              >
                ยกเลิก
              </Button>
            )}
            {loading ? (
              <Spinner color="secondary" />
            ) : (
              <Button
                style={{
                  backgroundColor: "#000",
                  borderColor: "#000",
                  color: "#FFF",
                  borderRadius: "1px",
                }}
                onClick={handleConfirmationConfirm}
              >
                ยืนยัน
              </Button>
            )}
          </ModalFooter>
        </Modal>

        {/* Error Modal */}
        <Modal isOpen={errorModalOpen} toggle={handleErrorClose}>
          <ModalHeader toggle={handleErrorClose}>
            มีปัญหาในการเชื่อมต่อ
          </ModalHeader>
          <ModalBody>
            ระบบมีปัญหาในการส่งข้อมูล กรุณาลองอีกครั้งในภายหลัง
            <br />
            There was an issue submitting your data. Please try again later.
          </ModalBody>
          <ModalFooter>
            <Button
              style={{
                backgroundColor: "#000",
                borderColor: "#000",
                color: "#FFF",
                borderRadius: "1px",
              }}
              onClick={handleErrorClose}
            >
              ตกลง
            </Button>
          </ModalFooter>
        </Modal>

        {/* Cancled */}
        <Modal isOpen={dupModalOpen} toggle={handleDupClose}>
          <ModalHeader toggle={handleDupClose}>แจ้งเตือน</ModalHeader>
          <ModalBody>
            หมายเลขโทรศัพท์หรืออีเมลถูกใช้ลงทะเบียนไปแล้ว
            <br />
          </ModalBody>
          <ModalFooter>
            <Button
              style={{
                backgroundColor: "#000",
                borderColor: "#000",
                color: "#FFF",
                borderRadius: "1px",
              }}
              onClick={handleDupClose}
            >
              ตกลง
            </Button>
          </ModalFooter>
        </Modal>

        {/* Dup Modal */}
        <Modal isOpen={inputErrModalOpen} toggle={handleInputErrClose}>
          <ModalHeader toggle={handleInputErrClose}>แจ้งเตือน</ModalHeader>
          <ModalBody>
            กรุณากรอกข้อมูลให้ครบถ้วน
            <br />
          </ModalBody>
          <ModalFooter>
            <Button
              style={{
                backgroundColor: "#000",
                borderColor: "#000",
                color: "#FFF",
                borderRadius: "1px",
              }}
              onClick={handleInputErrClose}
            >
              ตกลง
            </Button>
          </ModalFooter>
        </Modal>

        <Modal size="xl" isOpen={concentsModal} toggle={handelConcents}>
          <ModalHeader toggle={handelConcents}>Consent</ModalHeader>
          <div
            style={modalStyle}
            dangerouslySetInnerHTML={{ __html: scrapedContent }}
          ></div>
          <ModalFooter>
            <Button
              style={{
                backgroundColor: "#000",
                borderColor: "#000",
                color: "#FFF",
                borderRadius: "1px",
              }}
              onClick={handelConcents}
            >
              ตกลง
            </Button>
          </ModalFooter>
        </Modal>

        {/* Not available */}
        <Modal isOpen={notAvailableModalOpen} toggle={handleNotAvailableClose}>
          <ModalHeader toggle={handleNotAvailableClose}>แจ้งเตือน</ModalHeader>
          <ModalBody>
            รอบเต็มกรุณาเลือกรอบใหม่
            <br />
          </ModalBody>
          <ModalFooter>
            <Button
              style={{
                backgroundColor: "#000",
                borderColor: "#000",
                color: "#FFF",
                borderRadius: "1px",
              }}
              onClick={handleNotAvailableClose}
            >
              ตกลง
            </Button>
          </ModalFooter>
        </Modal>

        {/* Dup Modal */}
        <Modal isOpen={dupCancelModalOpen} toggle={handleDupCancelClose}>
          <ModalHeader toggle={handleDupCancelClose}>แจ้งเตือน</ModalHeader>
          <ModalBody>
            หมายเลขโทรศัพท์หรืออีเมลถูกยกเลิกลงทะเบียนไปแล้ว
            <br />
          </ModalBody>
          <ModalFooter>
            <Button
              style={{
                backgroundColor: "#000",
                borderColor: "#000",
                color: "#FFF",
                borderRadius: "1px",
              }}
              onClick={handleDupCancelClose}
            >
              ตกลง
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </div>
  );
};

export default RegistrationFormForeverMegaBangna;
