import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation } from "react-router-dom";
import imgLipsP1 from "./images/Thank_page_1.jpg"; // Adjust the path as necessary
import imgLipsP2 from "./images/Thank_page_2.jpg"; // Adjust the path as necessary

const ConfirmPageForeverMegaBangna = () => {
  const location = useLocation();
  const { value } = location.state || {};
  const { timeP } = location.state || {};
  const [date, setDate] = React.useState("");
  const [time, setTime] = React.useState("");

  useEffect(() => {
    console.log(value);
    console.log(timeP);
    if (value) {
      console.log(value.date_str);
      console.log(value.time_str);
      
      setDate(value.date_str);
      setTime(value.time_str);
      // const [year, month, day] = value.split('-');
      // const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      
      // // If day is less than 10, remove the leading zero
      // // const dayFormatted = parseInt(day, 10);
      // const dayFormatted = parseInt(day, 10) + 1;
      
      // const formattedDate = `${dayFormatted} ${months[parseInt(month) - 1]} ${year}`;
      // setDate(formattedDate);
    }
  }, [value]);

  return (
    <div style={{paddingTop : "25px"}}> 
      <div className="container">
      <br></br>
          <p style={{ textAlign: "center" }}>
            ขอบคุณสำหรับการลงทะเบียน
            <br />
          </p>
          <p style={{ textAlign: "center" }}>
          เชิญคุณร่วม Forever Makeup Workshop ที่จะมอบผิวโกลว์สวยสุขภาพดี ในแบบฉบับ Jisoo Forever Glow Look 30 นาที​ พร้อมแนะนำ คุชชั่นใหม่ Dior Forever Hydra Glow Cushion พร้อมเคสคุชชั่น 3 สไตล์ ตลอดจนถึงเทคนิคในการเลือกใช้ไฮไลท์ Luminizer ใหม่ 7 เฉดสี และ Bronzer ใหม่ ให้คุณเนรมิตความเปล่งประกาย สร้างมิติบนใบหน้า หลายระดับปรับแต่งได้ในแบบคุณ
          </p>

          <br />
          <p style={{ textAlign: "center", fontWeight: "bold" }}>วันที่ {date}</p>
          <p style={{ textAlign: "center", fontWeight: "bold" }}>เวลา {time}</p>
          <br />
          <p style={{ textAlign: "center" }}>
            ณ พื้นที่จัดงานอีเกียล็อบบี้ ชั้น 1
            <br />
            ศูนย์การค้าเซ็นทรัล เมกาบางนา
            <br />
            วันที่ 17 – 30 เมษายน 2568
          </p>
          <p style={{ textAlign: "center" }}>
          รอรับข้อความ SMS และแสดงข้อความที่ได้รับที่จุดลงทะเบียน{" "}
            <br></br><br></br>จำนวนจำกัด 1ท่าน : 1สิทธิ์
          </p>
          <br></br><br></br>
        </div>
        <div style={{display: "flex", maxWidth: "100%",justifyContent: "center"}}>
        <div style={{maxWidth: "215px"}}>
          <img
            src={imgLipsP1}
            alt="Event"
            // className="img-fluid mb-4"
            style={{ width: "100%", height: "auto", maxWidth: "none" }}
          />
        </div>
        <div style={{maxWidth: "215px"}}>
          <img
            src={imgLipsP2}
            alt="Event"
            // className="img-fluid mb-4"
            style={{ width: "100%", height: "auto", maxWidth: "none" }}
          />
        </div>
        {/* <div style={{maxWidth: "215px"}}>
          <img
            src={imgLipsP3}
            alt="Event"
            // className="img-fluid mb-4"
            style={{ width: "100%", height: "auto", maxWidth: "none" }}
          />
        </div> */}
      </div>
    </div>
  );
};

export default ConfirmPageForeverMegaBangna;
